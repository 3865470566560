import React, { useState, useEffect } from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import logo from '../../image/logo.png';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const img = document.getElementById('logo');
      if (window.scrollY > 10) {
        img.classList.add('logomove');
      } else {
        img.classList.remove('logomove');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header>
      <img className="logo" id="logo" src={logo} alt="Logo" height="100" />
      <div className="menu-toggle-container" onClick={toggleMenu}>
        <span style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>Menu</span>
        <div className="menu-toggle">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <nav className={menuOpen ? 'show' : ''}>
        <ul>
          <li>
            <NavLink to="/" onClick={() => setMenuOpen(false)}>Home</NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={() => setMenuOpen(false)}>About</NavLink>
          </li>
          <li>
            <NavLink to="/project" onClick={() => setMenuOpen(false)}>Project</NavLink>
          </li>
          <li>
            <NavLink to="/skill" onClick={() => setMenuOpen(false)}>Skill</NavLink>
          </li>
          <li>
            <NavLink to="/education" onClick={() => setMenuOpen(false)}>Education</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
